export type AppConfig = {
	apiPrefix: string;
	authenticatedEntryPath: string;
	unAuthenticatedEntryPath: string;
	locale: string;
	enableMock: boolean;
	dateFormat: string;
	dateTimeFormat?: string;
	cacheTime?: number;
};

const appConfig: AppConfig = {
	apiPrefix: process.env.API_URL!,
	authenticatedEntryPath: '',
	unAuthenticatedEntryPath: '/',
	locale: 'en',
	enableMock: false,
	dateFormat: 'PPpp',
	dateTimeFormat: 'PPpp',
	cacheTime: 10_000,
};

export default appConfig;
