import ApiService from './ApiService';
import { ApiCore } from './base/Core';

import type { ForgotPassword, ResetPassword, SignInCredential } from '@/@types/auth';

class Auth extends ApiCore {
	/*
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 ** METHOD NAME - apiSignIn
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 */
	async apiSignIn(data: SignInCredential) {
		await this.post({
			username: data.username,
			password: data.password,
		});

		return Promise.resolve({
			data: {
				auth: {
					AccessToken:
						'eyJraWQiOiI5V05XSEFXcjBaK0Nzc2RZVHpnMkY2cnpYM1pGSExcL3hURmtKUlBYd3pScz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlZTk5OTkxOC03ZjY0LTQ0NDctYWEzYS0xMzQ2NjI4ZjU1MzYiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV95UGUzOFZZYVQiLCJjbGllbnRfaWQiOiI0Zm90amplYmduZ3VjcnV1dG1wOGMyYm1hNyIsIm9yaWdpbl9qdGkiOiI4ZDFkMTk4OS00NjU0LTQzMDItOTE5Yi0xYzk4MTY0NDU5ZmEiLCJldmVudF9pZCI6IjQyYTZlYmRlLWMxNGEtNDkyOC1hMTUzLTA4YzVhMzcxNWY4MyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MDk5MDM3MjgsImV4cCI6MTcwOTk5MDEyOCwiaWF0IjoxNzA5OTAzNzI4LCJqdGkiOiIwNWY5OTFjYi03ZDJkLTQ3ODItYmQzMy0xNzc5NWQ2NWYxNWIiLCJ1c2VybmFtZSI6Imp0bWdpbyJ9.mz6y8djIbWXpLYbXIc1jLGY_-Zv0fzA6L8bYv1Zu5TRFkJsFX6Yc1q0Wu2pvf4dIibg-099DHYbHw-L_OnnW7r2e6szLW74D7JJfK_-dH_W1i8JZ9C3yE1iT_jbxd_Q7lrdwveByBw_EPHAKqz34yfW_7d7EhbfEaAi_wJP6PJgWBAMMoj5jGEj0wu5ECMsZ2M-FX5cW0Xscuxu2rFu_LFb0q4_QUSpjQOxvyXsbvnN7sC1KwSkv3TOcHNply6BUb0RMu0Tq11MRTTw3K9SIGeB_Avi1fB4KZo_F5d2--iGdABT3US6j2LvTpGBpMXiNJy9FniTnkigIRdZpdMSKvg',
					ExpiresIn: 86400,
					TokenType: 'Bearer',
					RefreshToken:
						'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.t1L2Nb7Bl-G0LE47hfhb-ivDDtWqOuH64L0PVyn8KJodK271v5X1Gs0hltVcdUkq6JVbyeIGN_ejW1KHu8uq73H5kUvDQGwuQ8gytHNAC4v3NO1QDFHEaNEWkluFjCMWZlZQ7_kfLekwBtXOe8plOmWAKRABvBqzy1AJZRgQASdyK3B6_rXx07BXeQyKQFCfUeTOC1rPKrH9U1KOb-4iBUJCAlAsw6atoXMndYe0PkezB9m6F39yrhaKulTj0KcrLQC2aDygFoIxS9wRFbpHkfnQhd9vFmMLN3bRQkpm5VHaWzP1us-QBfY7bX5kito_28wLmtWndfiuOUKjssOk5w.02AR1tEHvfipAv-U.D084bYs_6MqCPI-W7X4-BIOrbJ5fgAio199EqcpXRyz3FjasNQirI5MaWQ4_JJa9cPVHBO0DuCBn33ZiECdc0-6ManyaOzRSobhzoqJjnDjImj6DKjfLXisTPvX7V3uv5iAfrVQMtzgnkCIoaQl3eLFKwgQ2aH8FHYe6iR2MeLT6gX7cyZ9wqiNAHm-vjyI5IBosyyTON1vv6db14yT9hU6zpU6Ta1ojo_05nm3BPODB9SDitsZM6-sS_q884X-iXwb009eXbOE7d2MpWtastScyhh3LobOp43K6d3fQoweNQdGVf_99crMKVFBwP9NkFxjU5E5WRs3HeK0wR7tmPS-W8VSbiWXWl-PFyzHP8Y9Qk-JlSI613aWFlJc6aEhIeGqAee5zBOlEuwkI21jr0AjReGJfKrqCPGYAtEX3PcNYN3kaSspMJtemqEswYlwUB4G51LIrRhAh6DEz9Ov_5fqep3-4dSPEsESknZIAx5rDeOgvrSBeW6rbFZSm7Ekw1I_rvtLmw0QSm-hrAGcKKvQDFT2qlTQ-R2tXIt8bZh3Z03TC_v9ger5RwBDFAbdy1bDg-jBMSHhwCnp8UD9WHFjnRh0rXGhDH6WxTNPQoT3Uvs6jt2uv8_PobdVFr1rmm5D517CnJaeX-xrzad7pQzMJhX3JQSiE-2-EVWcjC_xrLHHdab0ZlvxYqjPe5sC1sVxOqyizECuo0CnBAkaj9hHu688o-4LUk7ZS9Vjk-bLDzRW1kYRAk2iwLuiByOWdkKEq09V4uMfm6Tt5LdBGi5VnzqccS6Y7eWICMIODv1sbcc-7sJjgXNHoh9Q3589hYxWfFwAgQoo1ADLt_smaygMhp7-yc5_MbQOGiUW2CGMpagA-dKaGHKWKkuYIaNGOz37Ptzz8eBCfEeJH5h1i5tiNvlPt4h9TX7JzNhKz-bWagCiYdSY-OQQlZJrY9GybXyjNjvOk73seFjnszE6mF0xpLD4QW6P33xTmIlDgWCF_fZgXBK5AbB8GW8AUBS2WQLKjYzuWyD6vlXKkxFjbQNgomwEHSLB110Lv8-9xtmdZHplMGdkPC7qomv9O7fu_hSFANJYGavTHsW5aIGze5ILrzYEbxCseq0Q3gcJQRyQhcXvlzmWgMeBeLmIrHpn3a4t2oWjKBWuhgBeErN0hVfur4tHyHWtRDZHDclVrd2o1oRmZs4X1xTMzknQe9mI53oCtGKGcyBXg2SykOEL0oNSpRgM2O_Bx902ykM5Aj4rg1vxcJEqAtWcIomtlxm8tb6lvdM-w.8elXrtzev-uv5KgYsYSYYw',
					IdToken:
						'eyJraWQiOiJCNFkrS1orRitEM0NiUnprU0VRb0ZJWlhMSFwvUzN4cnh3d1drNTlKS1h6Zz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlZTk5OTkxOC03ZjY0LTQ0NDctYWEzYS0xMzQ2NjI4ZjU1MzYiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3lQZTM4VllhVCIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImNvZ25pdG86dXNlcm5hbWUiOiJqdG1naW8iLCJnaXZlbl9uYW1lIjoiSm9zaHVhIiwib3JpZ2luX2p0aSI6IjhkMWQxOTg5LTQ2NTQtNDMwMi05MTliLTFjOTgxNjQ0NTlmYSIsImF1ZCI6IjRmb3RqamViZ25ndWNydXV0bXA4YzJibWE3IiwiZXZlbnRfaWQiOiI0MmE2ZWJkZS1jMTRhLTQ5MjgtYTE1My0wOGM1YTM3MTVmODMiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcwOTkwMzcyOCwibmFtZSI6Ikpvc2h1YSBHb256YWxleiIsInBob25lX251bWJlciI6IisxMTg1MDg3OTEzNzkiLCJleHAiOjE3MDk5OTAxMjgsImlhdCI6MTcwOTkwMzcyOCwiZmFtaWx5X25hbWUiOiJHb256YWxleiIsImp0aSI6IjE3NmY0YjBjLTUwYTktNDZiMi1hMjI1LTM1MjNjMjkwMGUxYiIsImVtYWlsIjoiamdAanRtZy5pbyJ9.xRLwzw7wFEczU5xD7Sk3b_XHbAzlOcOymquDvMOzeAvrAbD1v8hBU2DvWISBpeLfe8cNJBZ-fZJJWub-k0Z1PHzVQLj8VUeXO9RqJjPxbWlXPwFLIB5IRAfFb_pq6mBFoFWms6_BdlSfTciTm05RtW3YC9FEKptntskPjRrXf6g7s0ktBGW4kERifDblUnZE6liU3UJFLvfxLUctybfxx44hzNqeYbpOxZdrNBwyHyDdP_rh-4S5QgAZdLb8YoxjgBHlzwnNIjBKVjZgZ5CSUlYpOWNiLe2-KaxsdATf6FUD2WruDFf7ga6GSg_Yc79i75Zuz8jU850BanDlidgJMw',
				},
				user: {
					id: 1,
					username: 'jtmgio',
					email: 'admin@zetaglobal.com',
					cognitoId: 'ee999918-7f64-4447-aa3a-1346628f5536',
					firstName: 'Joshua',
					lastName: 'Gonzalez',
					mainPhone: '+18508791379',
					state: '',
					courtName: '',
					courtId: '',
					isActive: 1,
					userRole: [
						{
							id: 1,
							userId: 1,
							roleId: 1,
						},
					],
					authLog: [],
				},
			},
		});
	}
	/*
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 ** METHOD NAME - apiClientSignIn
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 */
	async apiClientSignIn(data: SignInCredential) {
		return Promise.resolve({
			data: {
				auth: {
					AccessToken:
						'eyJraWQiOiI5V05XSEFXcjBaK0Nzc2RZVHpnMkY2cnpYM1pGSExcL3hURmtKUlBYd3pScz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlZTk5OTkxOC03ZjY0LTQ0NDctYWEzYS0xMzQ2NjI4ZjU1MzYiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV95UGUzOFZZYVQiLCJjbGllbnRfaWQiOiI0Zm90amplYmduZ3VjcnV1dG1wOGMyYm1hNyIsIm9yaWdpbl9qdGkiOiI4ZDFkMTk4OS00NjU0LTQzMDItOTE5Yi0xYzk4MTY0NDU5ZmEiLCJldmVudF9pZCI6IjQyYTZlYmRlLWMxNGEtNDkyOC1hMTUzLTA4YzVhMzcxNWY4MyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MDk5MDM3MjgsImV4cCI6MTcwOTk5MDEyOCwiaWF0IjoxNzA5OTAzNzI4LCJqdGkiOiIwNWY5OTFjYi03ZDJkLTQ3ODItYmQzMy0xNzc5NWQ2NWYxNWIiLCJ1c2VybmFtZSI6Imp0bWdpbyJ9.mz6y8djIbWXpLYbXIc1jLGY_-Zv0fzA6L8bYv1Zu5TRFkJsFX6Yc1q0Wu2pvf4dIibg-099DHYbHw-L_OnnW7r2e6szLW74D7JJfK_-dH_W1i8JZ9C3yE1iT_jbxd_Q7lrdwveByBw_EPHAKqz34yfW_7d7EhbfEaAi_wJP6PJgWBAMMoj5jGEj0wu5ECMsZ2M-FX5cW0Xscuxu2rFu_LFb0q4_QUSpjQOxvyXsbvnN7sC1KwSkv3TOcHNply6BUb0RMu0Tq11MRTTw3K9SIGeB_Avi1fB4KZo_F5d2--iGdABT3US6j2LvTpGBpMXiNJy9FniTnkigIRdZpdMSKvg',
					ExpiresIn: 86400,
					TokenType: 'Bearer',
					RefreshToken:
						'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.t1L2Nb7Bl-G0LE47hfhb-ivDDtWqOuH64L0PVyn8KJodK271v5X1Gs0hltVcdUkq6JVbyeIGN_ejW1KHu8uq73H5kUvDQGwuQ8gytHNAC4v3NO1QDFHEaNEWkluFjCMWZlZQ7_kfLekwBtXOe8plOmWAKRABvBqzy1AJZRgQASdyK3B6_rXx07BXeQyKQFCfUeTOC1rPKrH9U1KOb-4iBUJCAlAsw6atoXMndYe0PkezB9m6F39yrhaKulTj0KcrLQC2aDygFoIxS9wRFbpHkfnQhd9vFmMLN3bRQkpm5VHaWzP1us-QBfY7bX5kito_28wLmtWndfiuOUKjssOk5w.02AR1tEHvfipAv-U.D084bYs_6MqCPI-W7X4-BIOrbJ5fgAio199EqcpXRyz3FjasNQirI5MaWQ4_JJa9cPVHBO0DuCBn33ZiECdc0-6ManyaOzRSobhzoqJjnDjImj6DKjfLXisTPvX7V3uv5iAfrVQMtzgnkCIoaQl3eLFKwgQ2aH8FHYe6iR2MeLT6gX7cyZ9wqiNAHm-vjyI5IBosyyTON1vv6db14yT9hU6zpU6Ta1ojo_05nm3BPODB9SDitsZM6-sS_q884X-iXwb009eXbOE7d2MpWtastScyhh3LobOp43K6d3fQoweNQdGVf_99crMKVFBwP9NkFxjU5E5WRs3HeK0wR7tmPS-W8VSbiWXWl-PFyzHP8Y9Qk-JlSI613aWFlJc6aEhIeGqAee5zBOlEuwkI21jr0AjReGJfKrqCPGYAtEX3PcNYN3kaSspMJtemqEswYlwUB4G51LIrRhAh6DEz9Ov_5fqep3-4dSPEsESknZIAx5rDeOgvrSBeW6rbFZSm7Ekw1I_rvtLmw0QSm-hrAGcKKvQDFT2qlTQ-R2tXIt8bZh3Z03TC_v9ger5RwBDFAbdy1bDg-jBMSHhwCnp8UD9WHFjnRh0rXGhDH6WxTNPQoT3Uvs6jt2uv8_PobdVFr1rmm5D517CnJaeX-xrzad7pQzMJhX3JQSiE-2-EVWcjC_xrLHHdab0ZlvxYqjPe5sC1sVxOqyizECuo0CnBAkaj9hHu688o-4LUk7ZS9Vjk-bLDzRW1kYRAk2iwLuiByOWdkKEq09V4uMfm6Tt5LdBGi5VnzqccS6Y7eWICMIODv1sbcc-7sJjgXNHoh9Q3589hYxWfFwAgQoo1ADLt_smaygMhp7-yc5_MbQOGiUW2CGMpagA-dKaGHKWKkuYIaNGOz37Ptzz8eBCfEeJH5h1i5tiNvlPt4h9TX7JzNhKz-bWagCiYdSY-OQQlZJrY9GybXyjNjvOk73seFjnszE6mF0xpLD4QW6P33xTmIlDgWCF_fZgXBK5AbB8GW8AUBS2WQLKjYzuWyD6vlXKkxFjbQNgomwEHSLB110Lv8-9xtmdZHplMGdkPC7qomv9O7fu_hSFANJYGavTHsW5aIGze5ILrzYEbxCseq0Q3gcJQRyQhcXvlzmWgMeBeLmIrHpn3a4t2oWjKBWuhgBeErN0hVfur4tHyHWtRDZHDclVrd2o1oRmZs4X1xTMzknQe9mI53oCtGKGcyBXg2SykOEL0oNSpRgM2O_Bx902ykM5Aj4rg1vxcJEqAtWcIomtlxm8tb6lvdM-w.8elXrtzev-uv5KgYsYSYYw',
					IdToken:
						'eyJraWQiOiJCNFkrS1orRitEM0NiUnprU0VRb0ZJWlhMSFwvUzN4cnh3d1drNTlKS1h6Zz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlZTk5OTkxOC03ZjY0LTQ0NDctYWEzYS0xMzQ2NjI4ZjU1MzYiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3lQZTM4VllhVCIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImNvZ25pdG86dXNlcm5hbWUiOiJqdG1naW8iLCJnaXZlbl9uYW1lIjoiSm9zaHVhIiwib3JpZ2luX2p0aSI6IjhkMWQxOTg5LTQ2NTQtNDMwMi05MTliLTFjOTgxNjQ0NTlmYSIsImF1ZCI6IjRmb3RqamViZ25ndWNydXV0bXA4YzJibWE3IiwiZXZlbnRfaWQiOiI0MmE2ZWJkZS1jMTRhLTQ5MjgtYTE1My0wOGM1YTM3MTVmODMiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcwOTkwMzcyOCwibmFtZSI6Ikpvc2h1YSBHb256YWxleiIsInBob25lX251bWJlciI6IisxMTg1MDg3OTEzNzkiLCJleHAiOjE3MDk5OTAxMjgsImlhdCI6MTcwOTkwMzcyOCwiZmFtaWx5X25hbWUiOiJHb256YWxleiIsImp0aSI6IjE3NmY0YjBjLTUwYTktNDZiMi1hMjI1LTM1MjNjMjkwMGUxYiIsImVtYWlsIjoiamdAanRtZy5pbyJ9.xRLwzw7wFEczU5xD7Sk3b_XHbAzlOcOymquDvMOzeAvrAbD1v8hBU2DvWISBpeLfe8cNJBZ-fZJJWub-k0Z1PHzVQLj8VUeXO9RqJjPxbWlXPwFLIB5IRAfFb_pq6mBFoFWms6_BdlSfTciTm05RtW3YC9FEKptntskPjRrXf6g7s0ktBGW4kERifDblUnZE6liU3UJFLvfxLUctybfxx44hzNqeYbpOxZdrNBwyHyDdP_rh-4S5QgAZdLb8YoxjgBHlzwnNIjBKVjZgZ5CSUlYpOWNiLe2-KaxsdATf6FUD2WruDFf7ga6GSg_Yc79i75Zuz8jU850BanDlidgJMw',
				},
				user: {
					id: 1,
					username: 'jtmgio',
					email: 'admin@zeta.com',
					cognitoId: 'ee999918-7f64-4447-aa3a-1346628f5536',
					firstName: 'Joshua',
					lastName: 'Gonzalez',
					mainPhone: '+18508791379',
					state: '',
					courtName: '',
					courtId: '',
					isActive: 1,
					userRole: [
						{
							id: 2,
							userId: 2,
							roleId: 2,
						},
					],
					authLog: [],
				},
			},
		});
	}
	/*
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 ** METHOD NAME - apiSignOut
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 */
	async apiSignOut() {
		return Promise.resolve({});
	}
	/*
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 ** METHOD NAME - apiForgotPassword
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 */
	async apiForgotPassword(data: ForgotPassword) {
		return ApiService.fetchData({
			url: '/auth/forgot-password',
			method: 'post',
			data: {
				usernameOrEmail: data.email,
			},
		});
	}
	/*
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 ** METHOD NAME - apiResetPassword
	 **----------------------------------------------------------------------------------------------------------------------------------------------
	 */
	async apiResetPassword(data: ResetPassword) {
		return ApiService.fetchData({
			url: '/auth/reset-password',
			method: 'post',
			data,
		});
	}
}

export default new Auth({
	url: 'login',
});
