import './locales';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import Layout from '@/components/layouts';
import Theme from '@/components/template/Theme';
import appConfig from '@/configs/app.config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import mockServer from './mock';
import store, { persistor } from './store';

const environment = process.env.NODE_ENV;
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {},
	},
});

if (appConfig.enableMock) {
	mockServer({ environment });
}

function App() {
	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<PersistGate loading={null} persistor={persistor}>
					<BrowserRouter>
						<Theme>
							<Layout />
						</Theme>
					</BrowserRouter>
				</PersistGate>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</Provider>
	);
}

export default App;
