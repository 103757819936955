import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';

export function removeUndefinedValues(obj) {
	const newObj = Array.isArray(obj) ? [] : {};
	for (const key in obj) {
		if (obj[key] !== undefined) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				newObj[key] = removeUndefinedValues(obj[key]);
			} else {
				newObj[key] = obj[key];
			}
		}
	}
	return newObj;
}

export const serialize = obj => {
	const str: any[] = [];
	for (const p in removeUndefinedValues(obj))
		if (obj.hasOwnProperty(p)) {
			str.push(p + '=' + obj[p]);
		}
	return str.join('&');
};

export function isEmpty(obj) {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function convertKeysToCamelCase(obj) {
	if (Array.isArray(obj)) {
		return obj.map(v => convertKeysToCamelCase(v));
	} else if (isPlainObject(obj)) {
		return Object.keys(obj).reduce((result, key) => {
			result[camelCase(key)] = convertKeysToCamelCase(obj[key]);
			return result;
		}, {});
	}
	return obj;
}
