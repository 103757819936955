import axios from 'axios';

import { handleError, handleResponse } from './Response';

export class ProviderApi {
	BASE_URL = process.env.API_URL!;
	appAxios;
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - constructo
	 **-------------------------------------------------------------------------------------
	 */
	constructor(options) {
		if (options?.useMock) {
			this.setBaseUrl(options.debugUrl);
		} else {
			this.setBaseUrl(process.env.API_URL!);
		}
		this.setAxios();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - setBaseUrl
	 **-------------------------------------------------------------------------------------
	 */
	setBaseUrl(url: string) {
		this.BASE_URL = url;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getHeaders
	 **-------------------------------------------------------------------------------------
	 */
	getHeaders() {
		const headers = {};
		return {
			withCredentials: true,
			headers: { ...headers },
		};
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - setAxios
	 **-------------------------------------------------------------------------------------
	 */
	setAxios() {
		this.appAxios = axios.create({
			baseURL: this.BASE_URL,
			withCredentials: true,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - get
	 **-------------------------------------------------------------------------------------
	 */
	get(resource: string, sendHeaders = true) {
		if (resource.endsWith('?')) {
			resource = resource.replace(/.$/, '');
		}
		return this.appAxios
			.get(`${this.BASE_URL}/${resource}`, sendHeaders ? this.getHeaders() : {})
			.then(handleResponse)
			.catch(handleError);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - list
	 **-------------------------------------------------------------------------------------
	 */
	list(resource: string) {
		return this.appAxios
			.get(`${this.BASE_URL}/${resource}`, this.getHeaders())
			.then(handleResponse)
			.catch(handleError);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getAll
	 **-------------------------------------------------------------------------------------
	 */
	getAll(resource: string) {
		return this.appAxios
			.get(`${this.BASE_URL}/${resource}`, this.getHeaders())
			.then(handleResponse)
			.catch(handleError);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getSingle
	 **-------------------------------------------------------------------------------------
	 */
	getSingle(resource: string, id: any, params?: any) {
		return this.appAxios
			.get(`${this.BASE_URL}/${resource}/${id}?${params}`, this.getHeaders())
			.then(handleResponse)
			.catch(handleError);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getSingle
	 **-------------------------------------------------------------------------------------
	 */
	postFile(resource: string, file: any) {
		const headers = this.getHeaders();
		const data: FormData = new FormData();
		data.append('file', file);
		return this.appAxios
			.post(`${this.BASE_URL}/${resource}`, data, {
				headers: {
					...headers.headers,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(handleResponse);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getSingle
	 **-------------------------------------------------------------------------------------
	 */
	postFiles(resource: string, files: any) {
		const headers = this.getHeaders();
		const data: FormData = new FormData();
		files.forEach(file => {
			data.append('file[]', file);
		});

		return this.appAxios
			.post(`${this.BASE_URL}/${resource}`, data, {
				headers: {
					...headers.headers,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(handleResponse);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - post
	 **-------------------------------------------------------------------------------------
	 */
	post(resource: string, model: any) {
		return this.appAxios
			.post(`${this.BASE_URL}/${resource}`, model, {
				withCredentials: true,
			})
			.then(handleResponse);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - put
	 **-------------------------------------------------------------------------------------
	 */
	put(resource: string, model: any) {
		return this.appAxios
			.put(`${this.BASE_URL}/${resource}`, model, this.getHeaders())
			.then(handleResponse)
			.catch(handleError);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - delete
	 **-------------------------------------------------------------------------------------
	 */
	delete(resource: string) {
		return this.appAxios
			.delete(`${this.BASE_URL}/${resource}`, this.getHeaders())
			.then(handleResponse);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - patch
	 **-------------------------------------------------------------------------------------
	 */
	patch(resource: string, model: any, sendHeaders = true) {
		return this.appAxios
			.patch(`${this.BASE_URL}/${resource}`, model, sendHeaders ? this.getHeaders() : {})
			.then(handleResponse);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - patch
	 **-------------------------------------------------------------------------------------
	 */
	remove(resource: string, id: number) {
		return this.appAxios
			.delete(`${this.BASE_URL}/${resource}/${id}`, this.getHeaders())
			.then(handleResponse)
			.catch(handleError);
	}
}
