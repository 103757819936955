import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://cd9914083d9ecd9542d2075ce3f904b2@o4508087656316928.ingest.us.sentry.io/4508088220975104',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		profilesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
