import { serialize } from '@/utils/object';
import * as Sentry from '@sentry/react';

import { ProviderApi } from './ProviderClass';

export interface iApiCore {
	list: (config?: any) => Promise<any>;
	getAll: (config?: any, path?: string) => Promise<any>;
	getSingle: (id: any, config?: any) => Promise<any>;
	post: (model: any, path?: any) => Promise<any>;
	put: (model: any, path: any) => Promise<any>;
	patch: (model: any, path?: any, sendHeaders?: boolean) => Promise<any>;
	remove: (id: number) => Promise<any>;
	postFile: (model: any, path?: any) => Promise<any>;
	get: (path?: any, sendHeaders?: boolean) => Promise<any>;
	delete: (path: any) => Promise<any>;
	postWithRetry: (model: any, path?: any) => Promise<any>;
}
export class ApiCore implements iApiCore {
	public list: (config?: any) => Promise<any>;
	public getAll: (config?: any, path?: string) => Promise<any>;
	public getSingle: (id: any, config?: any) => Promise<any>;
	public post: (model: any, path?: any) => Promise<any>;
	public postFile: (model: any, path?: any) => Promise<any>;
	public put: (model: any, path: any) => Promise<any>;
	public patch: (model: any, path?: any, sendHeaders?: boolean) => Promise<any>;
	public remove: (id: number) => Promise<any>;
	public get: (path?: string) => Promise<any>;
	public delete: (path: string) => Promise<any>;
	public getRaw: (path: string, config?: any) => Promise<any>;
	public postWithRetry: (model: any, path?: any, config?: any) => Promise<any>;
	public provider;
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(options: any) {
		this.provider = new ProviderApi(options);
		/*
		 **-------------------------------------------------------------------------------------
		 ** FN NAME - postWithRetry
		 **-------------------------------------------------------------------------------------
		 */
		this.postWithRetry = async (
			model: any,
			path: any = '',
			config = {
				retries: 3,
				delay: 2000,
			}
		): Promise<any> => {
			for (let attempt = 1; attempt <= config.retries; attempt++) {
				try {
					const response = await this.post(model);
					return response;
				} catch (error) {
					if (attempt === config.retries) {
						const formedError = new Error('Failed to post data to LP API');
						Sentry.captureException(formedError, {
							tags: {
								section: 'landing-page-submission',
								severity: 'high',
							},
							extra: {
								model,
								path: options.url + path,
								config,
								env: {
									env: process.env.NODE_ENV,
									api_url: process.env.API_URL,
									url: window.location.href,
								},
								error,
							},
						});
						throw error;
					}
					await new Promise(resolve => setTimeout(resolve, config.delay));
				}
			}
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - getAll
		 **-------------------------------------------------------------------------------------
		 */
		this.list = (config = { queryParams: '' }): Promise<any> => {
			if (options?.useMock) {
				return Promise.resolve(options.mockData);
			}
			let url = `${options.url}?${serialize(config?.queryParams).replace('?', '')}`;
			if (config?.resourcePath) {
				url = `${config.resourcePath.replace('/', '')}&${serialize(
					config?.queryParams
				).replace('?', '')}`;
			}
			return this.provider.getAll(url);
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - getAll
		 **-------------------------------------------------------------------------------------
		 */
		this.getAll = ({ queryParams = '' }, path = ''): Promise<any> => {
			return this.provider.getAll(
				`${options.url + path}?${serialize(queryParams).replace('?', '')}`
			);
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - getAll
		 **-------------------------------------------------------------------------------------
		 */

		this.get = (path = '', sendHeaders = true) => {
			return this.provider.get(options.url + path, sendHeaders);
			//return this.provider.get(options.url + path, sendHeaders);
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - getAll
		 **-------------------------------------------------------------------------------------
		 */

		this.getRaw = (path = '', config = {}) => {
			return this.provider.get(`${path}?${serialize(config?.queryParams)}`);
		};

		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - getSingle
		 **-------------------------------------------------------------------------------------
		 */
		this.getSingle = (id: any, config = {}): Promise<any> => {
			return this.provider.getSingle(`${options.url}`, id, serialize(config?.queryParams));
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - post
		 **-------------------------------------------------------------------------------------
		 */
		this.post = (model: any, path: any = ''): Promise<any> => {
			return this.provider.post(options.url + path, model);
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - post
		 **-------------------------------------------------------------------------------------
		 */
		this.postFile = (path: any = '', model: any): Promise<any> => {
			return this.provider.postFile(options.url + path, model);
		};

		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - put
		 **-------------------------------------------------------------------------------------
		 */
		this.put = (model: any, path: any = ''): Promise<any> => {
			return this.provider.put(options.url + path, model);
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - patch
		 **-------------------------------------------------------------------------------------
		 */
		this.patch = (model: any, path: any = '', sendHeaders = true): Promise<any> => {
			return this.provider.patch(options.url + path, model, sendHeaders);
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - remove
		 **-------------------------------------------------------------------------------------
		 */
		this.remove = (id: any): Promise<any> => {
			return this.provider.remove(options.url, id);
		};
		/*
		 **-------------------------------------------------------------------------------------
		 ** METHOD NAME - delete
		 **-------------------------------------------------------------------------------------
		 */

		this.delete = (path = '') => {
			return this.provider.delete(options.url + path);
		};
	}
}
