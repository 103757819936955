import { useNavigate } from 'react-router-dom';

import appConfig from '@/configs/app.config';
import authApi from '@/services/AuthService';
import { setUser, signInSuccess, signOutSuccess, useAppDispatch, useAppSelector } from '@/store';

import useQuery from './useQuery';

import type { SignInCredential } from '@/@types/auth';

type Status = 'success' | 'failed';

function useAuth() {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const query = useQuery();

	const { token, signedIn } = useAppSelector(state => state.auth.session);

	const signIn = async (
		values: SignInCredential
	): Promise<
		| {
				status: Status;
				message: string;
				authority: string;
		  }
		| undefined
	> => {
		try {
			const authority = 'DEMO';
			const resp = await authApi.apiSignIn(values);
			if (resp) {
				const token = resp.data.auth.AccessToken;
				dispatch(signInSuccess(token));
				if (resp.data.user) {
					dispatch(
						setUser({
							...resp.data.user,
							authority: [authority],
						})
					);
				}
				return {
					status: 'success',
					message: '',
					authority,
				};
			}
		} catch (errors: any) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
				authority: '',
			};
		}
	};

	const signInSSO = async values => {
		const resp = await authApi.apiClientSignIn(values);
		const token = resp.data.auth.AccessToken;
		const authority = 'DEMO';

		dispatch(signInSuccess(token));
		dispatch(
			setUser({
				...resp.data.user,
				username: values.username,
				authority: [authority],
			})
		);
		return {
			status: 'success',
			message: '',
			authority,
		};
	};

	const handleSignOut = () => {
		dispatch(signOutSuccess());
		dispatch(
			setUser({
				avatar: '',
				username: '',
				email: '',
				authority: [],
			})
		);
		localStorage.clear();
		navigate(appConfig.unAuthenticatedEntryPath);
	};

	const signOut = async () => {
		handleSignOut();
	};

	return {
		authenticated: token && signedIn,
		signIn,
		signOut,
		signInSSO,
	};
}

export default useAuth;
